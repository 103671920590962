import React, { useState } from "react";
import { textToNumber } from "../utils/text-transform";

const FORM_ENDPOINT = "https://hooks.slack.com/services/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";

const ContactForm = () => {
  const [submitted, setSubmitted] = useState(false);
  const [submittedError, setSubmittedError] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const handleSubmit = (e) => {
    e.preventDefault();

    const inputs = e.target.elements;
    const values = [];

    for (let i = 0; i < inputs.length; i++) {
      if (inputs[i].name) {
        if (inputs[i].name === 'email') {
          values.push(textToNumber(inputs[i].value))
        } else {
          values.push(inputs[i].value);
        }
      }
    }

    fetch(FORM_ENDPOINT, {
      method: 'POST',
      headers: {
        Accept: 'application/json'
      },
      body: JSON.stringify({text: values.join(",")}),
    })
      .then((response) => {
        setSubmitted(true);
        if (!response.ok) {
          setSubmittedError(true);
          setResponseMessage('Could not submit your enquiry right now');
        } else {
          setResponseMessage('Message sent successfully');
        }
      })
      .catch((err) => {
        setSubmitted(true);
        setSubmittedError(true);
        setResponseMessage(`sending the message failed permanently: ${err}`);
      });
  };

  if (submitted && submittedError) {
    return (
      <>
        <div>Error: {responseMessage}</div>
        <div className="text-2xl">Please try again later</div>
      </>
    );
  } else if (submitted && !submittedError) {
    return (
      <>
        <div>{responseMessage}</div>
        <div className="text-2xl">Thank you!</div>
        <div className="text-md">We'll be in touch soon</div>
      </>
    );
  }

  return (
    <form
      action={FORM_ENDPOINT}
      onSubmit={handleSubmit}
      method="POST"
    >
      <div>
        <input
          type="text"
          placeholder="Your name"
          name="name"
        />
      </div>
      <div>
        <input
          type="email"
          placeholder="Email"
          name="email"
        />
      </div>
      <div className="submitbutton">
        <button
        className="submit"
          type="submit"
        >
          Send Enquiry
        </button>
      </div>
    </form>
  );
};

export default ContactForm;