import './App.css';
import ContactForm from './components/contact-form';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h2><span className='site-title'>wethu.africa</span> is for sale</h2>
        <p>&nbsp;</p>
        <div className="contact-form">
          <ContactForm />
        </div>
      </header>
    </div>
  );
}

export default App;
